import "./about.scss";
import CircleImg from "../../assets/circle.png";
import CircleImgMobile from "../../assets/circle-mobile.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const About = () => {
  const [rotate, setRotate] = useState(0);
  const [disableClick, setDisableClick] = useState(false);
  const { t } = useTranslation();

  return (
    <div id="#about" className="about">
      <div
        className={`image--wrapper image ${
          rotate === 1 ? "image--active" : rotate === 2 ? "image--reset" : ""
        }`}
      >
        <img
          draggable={false}
          src={CircleImg}
          alt=""
          onClick={() => {
            if (!disableClick) {
              setDisableClick(true);
              setRotate((prevState) => (prevState === 2 ? 1 : prevState + 1));
            }
            setTimeout(() => {
              setDisableClick(false);
            }, 1000);
          }}
        />
      </div>
      <div className="aboutUsText">
        <h2>{t("about_us")}</h2>
        {[...Array(6)].map((a, index) => (
          <p key={index}>{t(`about_us_text${index + 1}`)}</p>
        ))}
        <h3>{t("ending_message")}</h3>
      </div>

      <img src={CircleImgMobile} alt="" className="circle-image" />
    </div>
  );
};
