import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "./map.scss";

const position = { lat: 42.42013603280211, lng: 19.25020940766034 };

export const Map = () => {
  return (
    <MapContainer
      style={{ height: "300px", width: "100%" }}
      className="map"
      center={position}
      zoom={16}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        position={position}
        icon={
          new Icon({
            iconUrl: markerIconPng,
            iconSize: [20, 35],
            iconAnchor: [12, 41],
          })
        }
      >
        <Popup>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps/place/Cekovic/@42.4202172,19.2502807,18.79z/data=!4m12!1m6!3m5!1s0x0:0x9bea48e78166bc7a!2sCekovic!8m2!3d42.4201175!4d19.2502047!3m4!1s0x0:0x9bea48e78166bc7a!8m2!3d42.4201175!4d19.2502047`}
          >
            Google Maps
          </a>
        </Popup>
      </Marker>
    </MapContainer>
  );
};
