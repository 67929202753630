import { ApolloProvider } from "@apollo/client";
import { client } from "./providers/apolloClient";
import { NewsPage } from "./pages/news/news";
import { Navigation } from "./components/navigation/navigation";
import { About } from "./pages/about/about";
import { Contact } from "./pages/contact/contact";

function App() {
  return (
    <ApolloProvider client={client}>
      <Navigation />
      <NewsPage />
      <About />
      <Contact />
    </ApolloProvider>
  );
}

export default App;
