import EmailIcon from "../../assets/icons/mail.svg";
import FacebookIcon from "../../assets/icons/fb.png";
import InstaIcon from "../../assets/icons/insta.png";
import CloseIcon from "../../assets/close.png";
import "./contact.scss";
import { Map } from "../../components/map/map";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useState } from "react";

export const Contact = () => {
  const { width } = useScreenWidth();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const ContactInfoComponent = () => (
    <div className="contact__column1">
      <div className="contact__header">{t("contact").toUpperCase()}</div>
      <div className="contact__header">{t("sales")}</div>
      <div>069014912</div>
      <div>063228972</div>
      <div>020669726</div>
      <div>020642420</div>
      <div className="contact__header">{t("commercial")}</div>
      <div>063222990</div>
      <div className="contact__email">
        <img src={EmailIcon} alt="email-icon" />
        <div>autika1@t-com.me</div>
      </div>
      <div className="contact__social-media">
        <a
          href="https://www.instagram.com/autodjelovi_cekovic"
          target="_blank"
          rel="noreferrer"
        >
          <img src={InstaIcon} alt="insta" />
        </a>
        <a
          href="https://www.facebook.com/autodjelovicekovic"
          target="_blank"
          rel="noreferrer"
        >
          <img src={FacebookIcon} alt="fb" />
        </a>
      </div>
    </div>
  );

  const MapAndAddressComponent = () => (
    <div className="contact__column2">
      <Map />
      <div className="contact__header">{t("address")}</div>
      <div>{t("address_name")}</div>
      <div className="contact__header">{t("working_hours")}:</div>
      <div>{t("working_days1")}</div>
      <div>{t("working_days2")}</div>
      <div>{t("working_days3")}</div>
    </div>
  );

  return (
    <div id="#contact" className="contact">
      {width > 959 ? (
        <>
          <ContactInfoComponent />
          <MapAndAddressComponent />
        </>
      ) : (
        <>
          <MapAndAddressComponent />
          <ContactInfoComponent />
        </>
      )}
      <div className="contact__terms" onClick={() => setIsOpen(true)}>
        {t("terms")}
      </div>
      <Modal
        isOpen={isOpen}
        style={{
          overlay: {
            zIndex: 1000,
          },
        }}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick
      >
        <img
          src={CloseIcon}
          alt="close"
          onClick={() => setIsOpen(false)}
          className="contact__modal-close"
        />
        <div className="contact__modal">
          <h2>Uslovi korišćenja</h2>
          <div>
            Podaci o kompaniji:
            <br />
            <br />
            „Autika“ d.o.o.
            <br />
            <br />
            Adresa: Sergeja Jesenjina br. 7, 81000, Podgorica, Crna Gora
            <br />
            <br />
            Kontakt telefon: +382 20 642 420
            <br />
            <br />
            E-mail adresa: autika1@t-com.me
            <br />
            <br />
            PIB: 02415119
            <br />
            <br />
            PDV: 30/31-03721-1
          </div>
          <h3>PRAVO NA JEDNOSTRANI RASKID UGOVORA</h3>
          <div>
            Poštovani potrošači, obavještavamo Vas da uskladu sa važećim Zakonom
            o zaštiti potrošača (Sl.list CG 002/14,006/14,043/15,070/17,067/19)
            kupovina obavljena putem naše internet stranice smatra se Ugovorom
            zaključenim van poslovnih prostorija, odnosno na daljinu, što
            podrazumjeva ugovor zaključen između trgovca i potrošača u okviru
            organizovanog sistema prodaje ili pružanja usluge bez istovremenog
            prisustva trgovca i potrošača na istom mjestu pri čemu se do
            trenutka zaključenja ugovora i za zaključenje ugovora isključivo
            koristi jedno ili više sredstava komunikacije na daljinu.
            <br />
            <br />
            Zakon o zaštiti potrošača u slučaju prodaje na daljinu utvrđuje
            PRAVO kupca da jednostrano raskine ugovor zaključen na daljinu, bez
            navođenja razloga, u roku od 14 dana od dana predaje robe kupcu ili
            trećem licu koje je odredio kupac, a koje nije prevoznik.
            <br />
            <br />
            Da biste mogli da ostvarite pravo na jednostrani raskid ugovora,
            morate da nas obavijestite o svojoj odluci o jednostranom raskidu
            ovog Ugovora prije isteka roka za jednostrani raskid i to
            nedvosmislenom izjavom poslatom poštom, faksom ili elektronskom
            poštom u kojoj ćete da navedete svoje ime, prezime, adresu i ako su
            dostupni, svoj broj telefona, faksa ili adresu elektronske pošte.
            Kupac je, kao prilog, dužan da ostavi i obrazac o jednostranom
            raskidu ugovora.
            <br />
            <br />
            Ako jednom porudžbenicom Kupac naruči više komada robe koja treba da
            bude isporučena odvojeno, rok počinje da teče od dana dostavljanja
            poslednjeg komada robe potrošaču.
            <br />
            <br />
            Obrazac za jednostrani raskid ugovora možete možete preuzeti{" "}
            <a href="/uslovi-koriscenja.docx" target="_blank" download>
              ovdje
            </a>
            .
            <br />
            <br />
            Obrazac Izjave o odustanku od ugovora se dostavlja na adresu firme:
            ul Sergeja Jesenjina br. 7, 81 000, Podgorica.
            <br />
            <br />
            Odustajanjem od ugovora,Kupac je dužan da vrati robu trgovcu, bez
            odlaganja, a najkasnije u roku od 14 dana od dana kada je poslao
            obrazac- izjavu o odustanku od ugovora.
            <br />
            <br />
            <strong>Direktne troškove povraćaja robe snosi Kupac.</strong>
            <br />
            <br />
            Proizvodi koji se vraćaju moraju biti nekorišćeni, nepostavljani,
            neoštećeni i u originalnoj ambalaži, mora biti priložen originalni
            računi obrazac- izjava o odustanku od ugovora. Izjava o odustanku od
            ugovora proizvodi pravno dejstvo od dana kada je poslata trgovcu.
            <br />
            <br />
            Povraćaj plaćenog iznosa možemo izvršiti nakon što nam roba bude
            vraćena ili nakon što nam dostavite dokaz da ste nam robu poslali
            nazad ako ste nas obavijestili prije nego što nam je roba vraćena.
            <br />
            <br />
            Ako Kupac ostvari pravo na jednostrani raskid ugovora, pruzalac
            usluga je dužan da kupcu bez odlaganja vrati iznos koji je Kupac
            platio po osnovu ugovora, a najkasnije u roku od 14 dana od dana
            prijema izjave o odustanku, na isti način kako je plaćanje bilo
            izvršeno.
            <br />
            <br />
            Kupac se obavezuje da vrati proizvod nekorišćen, nepostavljan,
            neoštećen i u originalnoj, neoštećenoj ambalaži i nepromenjenoj
            količini. Vraćanje pojedinačnih djelova setova{" "}
            <strong>nije dozvoljeno</strong>.
            <br />
            <br />
            Kupac odgovara za svako umanjenje vrijednosti robe koje je rezultat
            rukovanja robom, postavljanjem robe i slično osim onog koje je bilo
            potrebno za utvrđivanje prirode, obilježja i funkcionalnosti robe.
          </div>
        </div>
      </Modal>
    </div>
  );
};
