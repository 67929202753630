import { useTranslation } from "react-i18next";
import { Button } from "../button/button";
import "./menu.scss";
import { useState, useEffect, useRef } from "react";
import MenuIcon from "../../assets/icons/menu.svg";

export const Menu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (menuRef.current && !menuRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window?.addEventListener("click", handleClick);

    return () => {
      window?.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="menu--container">
      <img
        src={MenuIcon}
        alt=""
        onClick={() => setIsOpen(true)}
        ref={menuRef}
      />
      <div
        className="menu__items"
        style={{ display: isOpen ? "flex" : "none" }}
      >
        <Button
          onClick={() => {
            document
              .getElementById("#about")
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          {t("about_us")}
        </Button>
        <Button
          onClick={() => {
            document
              .getElementById("#contact")
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          {t("contact")}
        </Button>
        <a
          href="http://autika.bestparts.shop/account"
          target="_blank"
          rel="noreferrer"
        >
          Webshop
          <div className="nav__buttons-shadow" />
        </a>
      </div>
    </div>
  );
};
