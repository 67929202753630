import { GET_IMAGES } from "../../queries/GET_IMAGES.graphql";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import LogoMobile from "../../assets/logo-mobile.png";
import "./news.scss";

export const NewsPage = () => {
  const [image, setImage] = useState<any>();

  const [callQuery, { loading }] = useLazyQuery(GET_IMAGES, {
    onCompleted: (data) => {
      setImage(data?.listImages?.data?.[0]?.image);
    },
  });

  useEffect(() => {
    callQuery();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="news">
      <div className="news__container">
        <div className="news__container--relative">
          {!loading && image && (
            <>
              <div className="news__shadow" />
              <div
                className="news__image-container"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </>
          )}
        </div>
      </div>
      <div className="news__image-carousel" />
      <img src={LogoMobile} className="news__logo-mobile" alt="" />
    </div>
  );
};
