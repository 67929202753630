import "./navigation.scss";
import Logo from "../../assets/logo.png";
import { Button } from "../button/button";
import MneFlag from "../../assets/icons/mne-flag.png";
import UkFlag from "../../assets/icons/uk-flag.png";
import { useTranslation } from "react-i18next";
import { Menu } from "../menu/menu";

export const Navigation = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="translation">
        <img alt="" src={MneFlag} onClick={() => i18n.changeLanguage("sr")} />
        <img alt="" src={UkFlag} onClick={() => i18n.changeLanguage("en")} />
      </div>
      <div className="nav__container">
        <img className="nav__logo" src={Logo} alt="logo" />
        <div className="nav__buttons-container">
          <div>
            <Button
              onClick={() =>
                document
                  .getElementById("#about")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              {t("about_us")}
            </Button>
            <div className="nav__buttons-shadow" />
          </div>
          <div>
            <Button
              onClick={() =>
                document
                  .getElementById("#contact")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              {t("contact")}
            </Button>
            <div className="nav__buttons-shadow" />
          </div>
          <div>
            <a
              href="http://autika.bestparts.shop/account"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Webshop</Button>
              <div className="nav__buttons-shadow" />
            </a>
          </div>
        </div>
        <Menu />
      </div>
    </>
  );
};
