import { gql } from "@apollo/client";

export const GET_IMAGES = gql`
  query GetImages {
    listImages(sort: createdOn_DESC) {
      data {
        id
        image
      }
    }
  }
`;
