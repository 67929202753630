import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  sr: {
    translation: {
      news: "Novosti",
      about_us: "O Nama",
      contact: "Kontakt",
      about_us_text1:
        "Naša priča počinje 2004. godine kada smo kao mala prodavnica iz Crne Gore počeli sa uvozom aftermarket i originalnih autodjelova sa ciljem da kupcima ponudimo vrhunski kvalitet po pristupačnim cijenama.",
      about_us_text2:
        "Mnogo godina kasnije, pred Vama je jedan od vodećih distributera autodjelova na teritoriji Crne Gore. I ako se naša struktura širila, cilj je ostao isti - nudimo vrhunski kvalitet po pristupačnim cijenama. U ponudi imamo renomirane brendove, za koje tvrdimo da zadovoljavaju svjetske norme sigurnosti i kvaliteta.",
      about_us_text3:
        'Audodjelovi Ceković posluju u okviru firme "Autika" D.O.O., a posjedujemo maloprodajni i veleprodajni objekat, web shop, kao i dostavna vozila što nam omogućava da u svakom momentu adekvatno odgovorimo na potrebe naših kupaca.',
      about_us_text4:
        "Znanje, ljubaznost, veliki asortiman djelova i kompetentne cijene su dio naše vizije, a preko stotinu lojalnih automehaničara i hiljade kupaca potvrđuju da ovu viziju živimo zajedno.",
      about_us_text5:
        "Bilo da tražite provjerenog dobavljača autodjelova za svoj servis ili djelove za sopstveni automobil naš prodajni tim će Vam sigurno pružiti kvalitetnu uslugu.",
      about_us_text6:
        "Vlasnike autoservisa pozivamo da kontaktiraju našu komercijalu kako bi dobili pristup našem savremenom web shopu i upoznali se sa pogodnostima koje nudimo servisima.",
      ending_message: "Zajedno na putu do najbolje usluge!",
      sales: "Prodaja",
      commercial: "Komercijala",
      address: "ADRESA",
      address_name: "Sergeja Jesenjina br.3, Zabjelo, Podgorica",
      working_hours: "Radno Vrijeme",
      working_days1: "Ponedeljak - Petak 8:00h - 20:00h",
      working_days2: "Subota 8:00h - 18:00h",
      working_days3: "Nedeljom ne radimo",
      terms: "Uslovi korišćenja",
    },
  },
  en: {
    translation: {
      news: "News",
      about_us: "About Us",
      contact: "Contact",
      about_us_text1:
        "Our story begins in 2004, when we started importing aftermarket and original car parts as a small shop from Montenegro, with a clear aim: to offer the best quality for an affordable price.",
      about_us_text2:
        "Many years later, we became one of the leading distributors for car parts in Montenegro. Although our company's structure changed over the years, the goal remained the same-the best quality for an affordable price. We provide renowned brands that adhere to safety and quality standards around the world.",
      about_us_text3:
        "Autodjelovi Cekovi is part of the 'Autika' d.o.o. company. Since we provide both retail, wholesale, a web store and delivery, a suitable response to our clients' needs is ensured.",
      about_us_text4:
        "Our vision includes expertise, friendliness, a large selection of car parts and competitive rates. The fact that we have more than 100 devoted mechanics and thousands of clients proves that we are working together to realize this vision.",
      about_us_text5:
        "Autodjelovi Cekovic is the ideal location for you if you're seeking a reliable seller of auto parts for your vehicle or a trusted supplier of car parts for your mechanic business. You may be confident that our sales team will give you the greatest customer service.",
      about_us_text6:
        "We encourage owners of auto mechanic shops to get in touch with our sales staff so they can gain access to our web store. Our sales team will explain in detail all the advantages we offer to workshops.",
      ending_message: "Together on the road to the best customer service!",
      sales: "Sales",
      commercial: "Commerical",
      address: "ADDRESS",
      address_name: "Sergeja Jesenjina No. 3, Zabjelo, Podgorica",
      working_hours: "Working Hours",
      working_days1: "Monday - Friday 8:00h - 20:00h",
      working_days2: "Saturday 8:00h - 18:00h",
      working_days3: "We don't work on Sunday",
      terms: "Terms of Use",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "sr",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
